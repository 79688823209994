body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  font-size: 14px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-size: 40px;
}

::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: #545454;
}

.card-text {
  line-height: 24px;
}

@media only screen and (max-width: 800px) {
  ::-webkit-scrollbar {
    display: none;
  }
}

.slick-prev::before {
  color: gray !important ;
  font-size: 24px !important;
  margin-left: -20px !important;
}

.slick-next:before {
  color: gray !important ;
  font-size: 24px !important;
  margin-right: -20px !important;
}

/* wp icon */
.whatsupIcon_container{
  position: fixed;
  bottom: 0;
  left: 10px;
  width: 100px;
  height: 100px;
  
  
 
  cursor: pointer;
  z-index: 9999;

}
.wpicon{
  /* color: #fff; */
  font-size: 60px;
  margin-right: 10px;
  color: #08811c !important;
}

.course-card {
  width: 20vw !important;
  margin: 2vw;
  border: 0.5px solid #4a4a4a24;
}

.box-shadow {
  box-shadow: 4px 10px 20px 2px rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 4px 10px 20px 2px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 4px 10px 20px 2px rgba(0, 0, 0, 0.12);
}

.featured-cards {
  /* width: 22vw; */
  min-height: 460px;
  height: 100%;
}
.featured-cards .card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* min-height: 1px; */
  padding: 1.25rem;
  height: 100%;
}

.course-block {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.course-img {
  height: 25vh;
}

.course-content {
  flex: 1;
  height: 25vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.course-cat,
.course-meta {
  height: auto;
}

.course-heading {
  min-height: 59px;

  text-overflow: ellipsis;
}

.course-container {
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  background-color: #e1e1e12a;
  margin-top: 5vh;
}

.close:hover {
  color: white;
}

@media only screen and (max-width: 800px) {
  .course-card {
    width: 55vw !important ;
    margin-left: auto;
    margin-right: auto;
    margin: 0;
  }

  .course-img {
    height: auto;
  }

  .featured-cards {
    width: 100%;
    margin-top: 20px;
    min-height: auto;
  }

  .btn-gradient1 {
    padding: 10px;
  }
}

.preview-modal-player {
  height: 200px;
  width: 400px !important;
}

.search-input:active,
.search-input:focus {
  border-color: #8c33df !important;
}

.button-enroll-course:hover {
  background-color: tomato !important;
  transition: all 0.3s ease-in-out;
  transform: scale(1.05);
}

.testimonial-item {
  max-width: 40vw !important;
}

.testimonials-slides .testimonial-item {
  opacity: 1;
}

@media only screen and (max-width: 600px) {
  .testimonial-item {
    max-width: 90vw !important;
    padding: 25px;
  }

  .testimonial-item h4 {
    font-size: 18px;
  }

  .slick-list {
    margin: 0 -5px;
  }
  .slick-slide > div {
    padding: 0 5px;
  }

  .course-card {
    width: 46vw !important ;
    margin-left: auto;
    margin-right: auto;
    margin: 2vw;
  }

  .course-block .course-content {
    padding: 15px 10px 15px;
  }

  .course-heading h5 {
    font-size: 15px;
  }

  .course-block .course-cat {
    font-size: 10px;
  }

  .course-card h5 {
    font-size: 14px;
  }
}

.counter-item {
  text-align: center;
}

.success-modal {
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  background-color: #003f3578;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.success-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  padding: 5% 10%;
}
.ctas {
  display: flex;
  justify-content: space-around;
  width: 100%;
  flex-wrap: wrap;
}
.ctas .btn {
  margin-bottom: 10px;
}

.trending-course-card {
  /* height: 70vh;  */
  /* min-height: 70vh; */
}

@media only screen and (max-width: 600px) {
  .trending-course-card {
    height: auto;
    width: 58vw !important ;
    margin-left: auto;
    margin-right: auto;
    margin: 2vw;
  }
}

.btn-gradient1 {
  border: none;
  background-color: #ff8e32 !important;
  background-image: linear-gradient(to right, #ff8e32, #ff5263) !important;
}

.btn-gradient2 {
  border: none;
  background-image: linear-gradient(to right, #8c3fff, #5c72ff);
}

.btn-gradient3 {
  border: none;
  background-image: linear-gradient(to right, #0a72d1, #01b6e1);
}

.btn-gradient1:hover,
.btn-gradient2:hover,
.btn-gradient3:hover {
  transform: scale(1.05);
  color: white !important;
}

.order-total {
  font-size: 20px;
}

.coure-box {
  position: relative;
  top: -60vh;
  background-color: white;
}

.accordion-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  width: 100%;
  background: none !important;
  height: 6vh;
  padding: 0 !important;
}

.accordion-button h5 {
  margin: 0 !important;
}

.accordion-button .section-header {
  padding: 0 !important;
}

.play-button:hover {
  color: tomato !important;
  /* transform: scale(1.05); */
}

.course-item-lp_lesson {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .coure-box {
    top: 0vh;
    margin-bottom: 5vh;
  }

  h1 {
    font-size: 32px;
  }

  .edutim-course-curriculum
    ul.curriculum-sections
    .section-header
    .section-title {
    font-size: 15px;
  }
}

.dropdown-true {
  transition: all 0.5s ease-in-out;
  transform: rotate(-180deg);
}

.dropdown-false {
  transition: all 0.5s ease-in-out;
  transform: rotate(0deg);
}

html {
  scroll-behavior: smooth;
}

.rating-div {
  display: flex;
  align-items: center;
  color: white;
  font-size: 16px;
}

.trending-course-card:hover {
  transform: scale(1.03);
  transition: all 0.3s ease-in-out;
}

.free-content-container {
  margin-top: 10vh;
}
.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.videoModalCourse .modal-header {
  background: #2b2b2b !important;
  border: none !important;
}
.videoModalCourse .modal-content {
  border: none !important;
}
.videoModalCourse .modal-title {
  color: #fff !important;
}
.videoModalCourse .close {
  color: #fff !important;
}
.ytp-chrome-top-buttons {
  display: none !important;
}
.cf_videoshare_referral {
  display: none !important;
}
.thankyou-haeding {
  text-align: center;
  font-size: 48px !important;
  font-weight: 800 !important;

  color: #444444;
  padding-top: 50px;
  padding-bottom: 25px;
}
.cta-thankyoubtn {
  border: #444444 solid 2px;
  color: #444444;
}
.cta-thankyoubtn a {
  color: #444444;
}
.cta-thankyoubtn a:hover {
  color: white;
}
.cta-thankyoubtn:hover {
  transform: scale(1.05);
  color: white !important;
  background-color: #444444;
}
.thankyou-subname {
  color: #444444 !important;
  font-weight: 600;
}
.thankyoupage {
  min-height: 100vh;
}
.header {
  padding: 30px;
  font-size: 40px;
  text-align: center;
  background: white;
}

/* Create two unequal columns that floats next to each other */
/* Left column */
.leftcolumn {
  float: left;
  width: 75%;
}

/* Right column */
.rightcolumn {
  float: left;
  width: 25%;
  padding-left: 20px;
}

/* Fake image */
.fakeimg {
  background-color: #aaa;
  width: 100%;
  padding: 20px;
}

/* Add a card effect for articles */
.card {
  background-color: white;
  padding: 20px;
  margin-top: 20px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

/* Footer */
.footer {
  padding: 20px;
  text-align: center;
  background: #ddd;
  margin-top: 20px;
}

/* Responsive layout - when the screen is less than 800px wide, make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 800px) {
  .leftcolumn,
  .rightcolumn {
    width: 100%;
    padding: 0;
  }
}
