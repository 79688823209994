.courseV2form-container {
  max-width: 600px;
  margin: 0 auto;
  background-color: #000068;
  padding: 50px 70px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.courseV2form-container input {
  width: 100%;
  height: 50px;
  border: 1px solid #ccc;
  padding: 10px;
  font-size: 16px;
  font-weight: 500;
  color: #000;
  background-color: #fff;
  outline: none;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.course-form-btn button {
  width: 100%;
  height: 50px;
  font-size: 21px;
  font-weight: 500;
  background-color: #eba709;
  color: #000;
  font-weight: 600;
  margin-top: 20px;
}

@media (max-width: 600px) {
  .courseV2form-container {
    padding: 35px 18px;
    margin: 14px;
  }
  .course-form-btn button {
    font-size: 18px;
  }
  .heading h3 {
    font-size: 22px;
  }
}
