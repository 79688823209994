.mobile-cart{
    display: none;
}

.desktop-cart{
    display: block;
}

@media only screen and (max-width: 900px) {

.desktop-cart{
    display: none;
}

.mobile-cart{
    display: block;
    background-color: rgb(245, 245, 245);
}


.course-card-container{
    width: 100%;
    min-height: 30vh;
    flex-direction: column;
}

.cart-course-div{
    display: flex;
 border: 1px solid rgb(197, 197, 197);
    width: auto;
    flex-direction: row;
  
    margin: 20px;
}

.text-course-container{
    padding-left: 20px;
    padding-top: 10px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.woocommerce a.remove{
    color : red !important; 
}


}


