.our-alumni-work-at-wrapper {
  max-width: 1140px;
  margin: 0 auto;
}
.our-alumni-logo-img {
  max-width: 200px;
  aspect-ratio: (3/2);
  object-fit: contain;
  display: flex;
  align-items: center;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .our-alumni-logo-img {
    max-width: 170px;
  }
}
@media (max-width: 600px) {
  .our-alumni-logo-img {
    max-width: 155px;
  }
}

/* overRiding default css */
.slick-track {
  display: flex !important;
  align-items: center !important;
}
