.course-player-container{
    min-height: 100vh;
}


@media only screen and (max-width: 800px) {
    .course-player-container{
        min-height: 100vh;
    
    }
    .coursemobileoff{
        display: none;
    }

.course-modal-player{
width: 380px !important;
}

}


.ytp-copylink-title,.ytp-copylink-title{
    display: none !important;
}


.html5-video-player:not(.ytp-big-mode) .ytp-chrome-top .ytp-copylink-button{
    display: none !important;
}

.ytp-chrome-top-buttons{
    display: none !important;
}
.frame-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */  
    padding-top: 25px;
    width: 300%; /* enlarge beyond browser width */
    left: -100%; /* center */
}
.frame-container iframe {
    position: absolute; 
    top: 0; 
    left: 0; 
    width: 100%; 
    height: 100%;
}
.iframe-wrapper {
    overflow: hidden;
    max-width: 100%;
 }
