.navbar-nav li{
    font-size: 16px;
}

.navbar-brand img{
    /* max-width: 30px; */
}

.cart-badge{
    background-color : #8c33df;
    border-radius: 50%;
   height: 19px;
   width: 20px;
  padding-top: 6px;
    transform: translate(-7px,-10px);
    color : white ;
}
.nav-item{
    display: flex;
    align-items: center;
}

.navbar-dropdown{
    padding : 0;
    background-color: transparent !important;
    color: #fff !important; 
    border: none !important;
    text-transform: none !important;
}


.navbar-dropdown:hover , .navbar-dropdown:active{
    background-color: transparent;
    border: none;
    color: yellow !important;
}


.navbar-dropdown .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show>.btn-primary.dropdown-toggle{
    background-color: transparent;
    border: none;
    color: dimgray;
}


.logo-brand{
    width: 160px;
    filter: brightness(100) ;
}

.shopping-mobile{
    display: none;
}



@media only screen and (max-width: 900px) {


    .shopping-mobile{
        display: block;
        margin-left: auto;
    }
    
    .shopping-web{
        display: block;
    }

    
.banner-2{
    /* background-image: none; */
  }
  

  }