/* The actual timeline (the vertical ruler) */
.timeline {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
}

.bg {
  background-color: #fcfcfc;
}

/* The actual timeline (the vertical ruler) */
.timeline::after {
  content: "";
  position: absolute;
  width: 6px;
  background-color: #803487;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
}

/* Customecontainer around content */
.Customecontainer {
  padding: 10px 40px;
  position: relative;
  background-color: inherit;
  width: 50%;
}

/* The circles on the timeline */
.Customecontainer::after {
  content: "";
  position: absolute;
  width: 25px;
  height: 25px;
  right: -17px;
  background-color: white;
  border: 4px solid #ff9f55;
  top: 15px;
  border-radius: 50%;
  z-index: 1;
}

/* Place the Customecontainer to the left */
.left {
  left: 0;
}

/* Place the Customecontainer to the right */
.right {
  left: 50%;
}

/* Add arrows to the left Customecontainer (pointing right) */
.left::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  right: 30px;
  border: medium solid white;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent #803487;
}

/* Add arrows to the right Customecontainer (pointing left) */
.right::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  left: 30px;
  border: medium solid white;
  border-width: 10px 10px 10px 0;
  border-color: transparent transparent transparent #803487;
}

/* Fix the circle for Customecontainers on the right side */
.right::after {
  left: -16px;
}

/* The actual content */
.content {
  padding: 20px 30px;
  background-color: white;
  position: relative;
  border-radius: 6px;
  border: 1px solid #000;
}

/* Note css */

.mainWrapper {
  /* background-color: cyan; */
  /* background-image: url("/assets/images/bg/notebg.jpg"); */
  background-image: url("/src/assets/bg1.png");

  background-size: cover;
  background-repeat: no-repeat;
  background-color: #eeeeee;
  margin: 30px auto;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.InnerContainerWrapper {
  background-color: #fff;
  padding: 38px;
}

.Card-img {
  width: 100px;
  height: auto;
}

.my-20 {
  margin-top: 80px;
  margin-bottom: 80px;
}

/* homepage */

.bg-banner {
  background-image: url("/src/assets/bg2.png");
  color: #fff;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #eeeeee;
  /* padding: 30px auto; */
  height: 65vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.leftContainer {
  color: #fff;
  /* z-index: 1000; */
}

.leftContainerTitle,
.leftContainerPara {
  color: #fff;
}

/* modal form css */

.rightContainer {
  width: 100%;
  height: 100%;
}

.form-heading {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
}

.rightContainer input {
  width: 100%;
  height: 50px;
  /* border-radius: 5px; */
  border: 1px solid #ccc;

  padding: 10px;

  font-size: 16px;
  font-weight: 500;
  color: #000;
  background-color: #fff;
  outline: none;
}

.Cbtn button {
  width: 100%;
  height: 50px;
  font-size: 20px;
  font-weight: 500;
  background-color: #803487;
}

@media (max-width: 1024px) {
  .bg-banner {
    height: 76vh;
  }
}

/* Media queries - Responsive timeline on screens less than 600px wide */

@media (max-width: 600px) {
  /* Place the timelime to the left */
  .timeline::after {
    left: 31px;
  }

  /* Full-width Customecontainers */
  .Customecontainer {
    width: 100%;
    padding-left: 70px;
    padding-right: 25px;
  }

  /* Make sure that all arrows are pointing leftwards */
  .Customecontainer::before {
    left: 60px;
    border: medium solid #000;
    border-width: 10px 10px 10px 0;
    border-color: transparent #000 transparent transparent;
  }

  /* Make sure all circles are at the same spot */
  .left::after,
  .right::after {
    left: 15px;
  }

  /* Make all right Customecontainers behave like the left ones */
  .right {
    left: 0%;
  }

  .mainWrapper {
    /* height: 449px; */
  }
}

@media (max-width: 425px) {
  .InnerContainerWrapper {
    padding: 5px;
  }
  .bg-banner {
    height: 100vh;
  }
}
