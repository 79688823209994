.modal-container {
  position: fixed;
  top: 100vh;
  right: 0;
  bottom: -100vh;
  left: 0;
  background-color: transparent;
}
.card {
  position: relative;
  background-color: #fff;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  padding: 32px;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
    0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
}
.close-cross {
  cursor: pointer;
  position: absolute;
  right: 3px;
  top: 7px;
  background-color: transparent;
  border: none;
}
.modal-content {
  height: inherit;
  border: none !important;
}
.card.active {
  opacity: 1;
  transition: 250ms;
}
.card.inactive {
  opacity: 0;
  transition: 150ms;
}
.modal-container.active {
  transition: 200ms ease-in;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 990;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #00000014; */
}
