.InvestMentBanking-container {
  /* background-image: url("../../../assets/coursesv2-bg.jpg");
      background-repeat: no-repeat;
      background-position: center; */
  overflow-x: hidden;
}
.header-section-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.InvestMentBanking-start-now-btn {
  background-color: #eba709;
  max-width: 200px;
  text-align: center;
  padding: 10px 20px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  font-style: 24px;
  font-weight: bold;
  color: #000;
  margin-top: 20px;
  margin-bottom: 10px;
  cursor: pointer;
}

@media (max-width: 600px) {
  .header-section-wrapper {
    align-items: center;
    text-align: center;
    margin: 0 auto;
  }
  .InvestMentBanking-start-now-btn {
    margin: 10px auto;
    min-width: 200px;
    margin-top: 5px;
  }
}
