.review-testimonial {
  max-width: 1140px;
  margin: 0 auto;
}
.review-testimonial-item-wrapper {
  margin: 7px;
}
.review-testimonial-item {
  padding: 25px;
  margin-bottom: 30px;
  background: #fff;
  border-radius: 10px;
  position: relative;
  margin: 7px;
  box-shadow: 2px 2px 10px;
}
.review-testimonial-item .review-testimonial-info-desc {
  font-family: "Kumbh Sans", sans-serif;
  color: #000;
  /* margin-top: 20px; */
  font-size: 16px;
}

.testimonials-slides-2 .review-testimonial-item,
.testimonials-slides-3 .review-testimonial-item {
  padding: 60px;
  margin-bottom: 20px;
  background: #f4f5f8;
  opacity: 1;
}

@media (max-width: 768px) {
  .review-testimonial-item {
    padding: 25px;
    margin: 5px;
  }
  .review-testimonial-item-wrapper {
    margin: 5px;
  }
}
@media (max-width: 600px) {
  .review-testimonial-item {
    padding: 11px;
    margin: 3px;
  }
  .review-testimonial-item-wrapper {
    margin: 4px;
  }

  .review-testimonial-item .review-testimonial-info-desc {
    font-size: 14px;
    line-height: 1.2rem;
    margin-top: 4px;
  }
  .review-testimonial-info-title h4 {
    font-size: 16px;
  }
}
