.whyFinladder-container {
  max-width: 1140px;
  margin: 0 auto;
}

.whyFinladder-card-img-wrapper {
  min-height: 100px;
  display: flex;
  align-items: center;
}
.whyFinladder-card-img-wrapper img {
  max-width: 100px;
  max-height: 100px;

  margin: 0 auto;
  margin-bottom: 10px;
  margin-top: 10px;
  /* background-color: #d4d2d2;
  border: 1px solid #707070;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  margin-bottom: 10px;
  margin-top: 10px; */
}

.whyFinladder-card-desc {
  font-family: "Kumbh Sans", sans-serif;
  font-size: 16px;
  max-width: 450px;
  margin: 0 auto;
  padding-bottom: 10px;
}
